import { faNote } from '@fortawesome/pro-regular-svg-icons/faNote'
import { ButtonView, Plugin, inlineHighlight } from 'ckeditor5'
import type { BlissbookEditor } from '../../BlissbookEditor'
import { getIconPath } from '../ui/util'

export const NEW_ANNOTATION_MARKER_NAME = 'new-annotation'

export class AnnotationUI extends Plugin {
  init() {
    const editor = this.editor as BlissbookEditor
    const { t } = editor

    // Renders a fake visual selection marker on an expanded selection.
    editor.conversion.for('editingDowncast').markerToHighlight({
      model: NEW_ANNOTATION_MARKER_NAME,
      view: {
        classes: ['ck-new-annotation-selected'],
      },
    })

    editor.ui.componentFactory.add('annotation', (locale) => {
      const buttonView = new ButtonView(locale)

      buttonView.set({
        icon: getIconPath(faNote),
        label: t('Annotate'),
        tooltip: true,
        withText: true,
      })

      // Callback executed once the button is clicked
      buttonView.on('execute', () => {
        editor.setShowNewAnnotation(true)
      })

      return buttonView
    })

    // Add a class when the annotation is selected
    inlineHighlight(editor, 'annotationId', 'span', 'ck-annotation-selected')
  }
}
