import { useForceRender } from '@blissbook/ui/hooks/useForceRender'
import { Dropdown } from '@blissbook/ui/lib'
import { cx } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { DecoupledEditor } from 'ckeditor5'
import React, { useEffect } from 'react'

export type CKEditorModeDropdownProps = {
  editor: DecoupledEditor
}

export function CKEditorModeDropdown({ editor }: CKEditorModeDropdownProps) {
  const forceRender = useForceRender()
  const trackChanges = editor.commands.get('trackChanges')

  useEffect(() => {
    trackChanges.on('change:value', forceRender)
    return () => {
      trackChanges.off('change:value', forceRender)
    }
  }, [editor])

  function setTrackingChanges(value: boolean) {
    if (value !== trackChanges.value) {
      editor.execute('trackChanges')
    }
  }

  const editOption = {
    className: 'tw-border-blurple-500 tw-text-blurple-500',
    icon: <FontAwesomeIcon icon={['far', 'pen']} />,
    label: 'Edit',
    onClick: () => {
      setTrackingChanges(false)
    },
  }

  const suggestOption = {
    className: 'tw-border-green-700 tw-text-green-700',
    icon: <FontAwesomeIcon icon={['far', 'pen-field']} />,
    label: 'Suggest',
    onClick: () => {
      setTrackingChanges(true)
    },
  }

  const options = [editOption, suggestOption]
  const activeOption = trackChanges.value ? suggestOption : editOption

  return (
    <Dropdown.Provider>
      <Dropdown.ToggleButton
        className={cx(
          'btn btn-input btn-sm tw-inline-flex tw-gap-2',
          activeOption.className,
        )}
        caret={false}
      >
        {activeOption.icon}
        {activeOption.label}
        <FontAwesomeIcon
          className='tw-text-sm'
          icon={['fas', 'chevron-down']}
        />
      </Dropdown.ToggleButton>
      <Dropdown.Menu>
        {options.map((option, index) => (
          <Dropdown.Item key={index} onClick={option.onClick}>
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown.Provider>
  )
}
