import type { Annotation } from '@blissbook/lib/blissbook'
// @ts-ignore: WIP Imports
import { Button, IconTooltip, ShapeGrid } from '@blissbook/ui/lib'
import { cx } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import type { BlissbookEditor } from '../../BlissbookEditor'
import { AddAnnotationView } from './AddAnnotationView'
import { AnnotationView } from './AnnotationView'

const helpTexts = [
  'Add annotations to guide your readers. Provide context or clarifications that they can see once your document is published.',
  'Highlight any text in the editor, then select “Annotate” to add your notes here.',
]

const shapes = [
  { x: 1, y: 0, type: 'square', color: 'purple-100', fill: true, rotate: 90 },
  { x: 2, y: 1, type: 'square', color: 'purple-300', fill: true, rotate: 90 },
  { x: 2, y: 2, type: 'wedge', color: 'purple-500', fill: true },
  { x: 3, y: 2, type: 'square', color: 'red-500', fill: true },

  { x: 1, y: 3, type: 'wedge', color: 'aqua-500', fill: true, rotate: 180 },
  { x: 2, y: 3, type: 'square', color: 'blurple-500', fill: true },
  { x: 3, y: 3, type: 'quotes', color: 'sunshine-500', scale: 0.6 },

  { x: 0, y: 4, type: 'wedge', color: 'sunshine-300', fill: true },
  { x: 1, y: 4, type: 'square', color: 'sunshine-500', fill: true },
  { x: 2, y: 4, type: 'square', color: 'red-500', fill: true },
  { x: 3, y: 4, type: 'wedge', color: 'aqua-500', fill: true, rotate: 180 },
]

const shapeSize = 40

export function AnnotationsView({
  activeAnnotationId,
  annotations,
  className,
  editor,
  onClose,
  setActiveAnnotationId,
  showNewAnnotation,
  style,
}: {
  activeAnnotationId?: string
  annotations: Annotation[]
  className?: string
  editor?: BlissbookEditor
  onClose: () => void
  setActiveAnnotationId: (annotationId: string) => void
  showNewAnnotation?: boolean
  style?: React.CSSProperties
}) {
  const showEmptyState = !annotations.length && !showNewAnnotation
  return (
    <div
      className={cx(
        'tw-flex tw-flex-col tw-gap-3 tw-bg-gray-50 tw-rounded tw-px-3 tw-pt-2 tw-pb-8 tw-overflow-auto',
        className,
      )}
      style={style}
    >
      <div className='tw-flex tw-items-center tw-justify-between tw-gap-2'>
        <div className='tw-flex tw-items-end tw-gap-2'>
          <div className='tw-font-semibold tw-text-lg'>Annotations</div>

          {!showEmptyState && (
            <IconTooltip icon={['far', 'question-circle']} placement='bottom'>
              <div className='tw-flex tw-flex-col tw-gap-2'>
                {helpTexts.map((helpText, index) => (
                  <div key={index}>{helpText}</div>
                ))}
              </div>
            </IconTooltip>
          )}
        </div>
        <Button className='btn-icon' onClick={onClose}>
          <FontAwesomeIcon icon='times' />
        </Button>
      </div>

      {showEmptyState && (
        <div className='tw-flex tw-flex-col tw-items-center tw-text-center tw-gap-4 tw-mt-4'>
          <ShapeGrid
            shapes={shapes}
            shapeSize={shapeSize}
            style={{ height: shapeSize * 5, width: shapeSize * 4 }}
          />

          <div className='tw-font-semibold'>Not annotations yet!</div>

          {helpTexts.map((helpText, index) => (
            <div className='tw-text-gray-700 tw-text-sm' key={index}>
              {helpText}
            </div>
          ))}
        </div>
      )}

      {annotations.map((annotation, index) => (
        <AnnotationView
          active={annotation.id === activeAnnotationId}
          annotation={annotation}
          editor={editor}
          index={index}
          key={annotation.id}
          onSetActive={() => setActiveAnnotationId(annotation.id)}
        />
      ))}

      {showNewAnnotation && <AddAnnotationView editor={editor} />}
    </div>
  )
}
