import { cx } from '@emotion/css'
import React from 'react'
import type { RevisionHistoryState } from '../useCKEditorState'
import './CKEditorRevisionHistoryView.scss'

export function CKEditorRevisionHistoryView({
  className,
  state,
}: {
  className?: string
  state: RevisionHistoryState
}) {
  return (
    <div
      className={cx('revision-history', className)}
      ref={state.viewerContainerRef}
    >
      <div className='tw-flex tw-h-full'>
        <div className='tw-flex-1 tw-min-w-0 tw-h-full'>
          <div ref={state.viewerEditorElementRef} />
        </div>
        <div
          className='tw-h-full tw-border-l-2 tw-border-gray-200'
          ref={state.viewerSidebarContainerRef}
          style={{ width: 280 }}
        />
      </div>
    </div>
  )
}
