import type { Annotation } from '@blissbook/lib/blissbook'
import React from 'react'
import type { BlissbookEditor } from '../../BlissbookEditor'
import { useSelectedState } from '../../hooks'
import { AnnotationsView } from './AnnotationsView'
import { selectAnnotationId } from './selectAnnotationId'

export function AnnotationsEditor(props: {
  annotations: Annotation[]
  className?: string
  editor: BlissbookEditor
  onClose: () => void
  showNewAnnotation: boolean
  style?: React.CSSProperties
}) {
  const { editor } = props
  const selected = useSelectedState(editor)

  function setActiveAnnotationId(annotationId: string) {
    selectAnnotationId(editor, annotationId)
  }

  return (
    <AnnotationsView
      {...props}
      activeAnnotationId={selected.annotationId}
      setActiveAnnotationId={setActiveAnnotationId}
    />
  )
}
