import type { BlissbookEditor } from '../../BlissbookEditor'
import { NEW_ANNOTATION_MARKER_NAME } from '../../plugins/Annotation/AnnotationUI'
import {
  hideFakeSelection,
  showFakeSelection,
} from '../../plugins/ui/FakeSelection'

export function hideNewAnnotationSelection(editor: BlissbookEditor) {
  hideFakeSelection(editor, NEW_ANNOTATION_MARKER_NAME)
}

export function showNewAnnotationSelection(editor: BlissbookEditor) {
  showFakeSelection(editor, NEW_ANNOTATION_MARKER_NAME)
}

export function selectAnnotationId(
  editor: BlissbookEditor,
  annotationId: string,
) {
  editor.model.change((writer) => {
    const model = editor.model
    const rootElement = model.document.getRoot()
    const rootRange = model.createRangeIn(rootElement)

    const ranges = []
    for (const item of rootRange.getItems()) {
      if (item.getAttribute('annotationId') === annotationId) {
        const range = writer.createRange(
          writer.createPositionAt(item.parent, item.startOffset),
          writer.createPositionAt(item.parent, item.endOffset),
        )
        ranges.push(range)
      }
    }

    writer.setSelection(ranges)
  })
}
